/** @format */

import styles from "./Sidebar.module.css";
import { GiWeightLiftingUp } from "react-icons/gi";
import { useLocation, useNavigate } from "react-router-dom";

import { MdAdminPanelSettings } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";

import { GoSignOut } from "react-icons/go";
import { useContext } from "react";
import { UserContext } from "../../../App";
import { UserService } from "../../../Api/User";
import { UserType } from "../../../Api/Interfaces";
import { MenuItems } from "./MenuItems";
import { IMenuItem } from "./IMenuItem";

export interface ISidebarProps {
    menuItem: IMenuItem;
}

export const Sidebar = (props: ISidebarProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const loggedInUser = useContext(UserContext);

    return (
        <div className={styles.sidebar}>
            <div>
                <div className={styles.branding}>
                    <img className={styles.brandingImg} src={process.env.PUBLIC_URL + "/fulltexticon.png"} />
                </div>

                <div className={styles.createSection}>
                    <button className={`${styles.sideBtn} ${styles.createPlanBtn}`} onClick={() => navigate("/plans/create")}>
                        <FaPlus />
                        Opprett plan
                    </button>
                </div>

                <div className={styles.buttonSection}>
                    {MenuItems.map((menuItem, i) => (
                        <button
                            key={i}
                            className={styles.sideBtn}
                            data-selected={menuItem.link == location.pathname && !menuItem.action}
                            onClick={() => {
                                if (menuItem.action) menuItem.action();
                                navigate(menuItem.link);
                            }}
                        >
                            {menuItem.icon}
                            {menuItem.text}
                        </button>
                    ))}
                </div>
            </div>
            <div className={styles.bottomSection}>
                {loggedInUser?.type === UserType.admin && (
                    <button
                        className={styles.sideBtn}
                        onClick={async () => {
                            navigate("/admin");
                        }}
                    >
                        <MdAdminPanelSettings />
                        Admin panel
                    </button>
                )}

                <button
                    className={styles.sideBtn}
                    onClick={async () => {
                        const shouldLogout = window.confirm("Er du sikker på at du vil logge ut?");
                        if (!shouldLogout) return;

                        await UserService.LogoutAsync();
                        navigate("/");
                        window.history.pushState(null, document.title, "/");
                        loggedInUser?.checkUserLogin();
                    }}
                >
                    <GoSignOut />
                    Logg ut
                </button>
            </div>
        </div>
    );
};
